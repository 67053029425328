import {mapGetters} from "vuex";
import templateTypes from "@/domain/Entities/Template/templateTypes";
import {
    COMPLETED,
    FULLY_EXECUTED,
    PENDING_ACKNOWLEDGEMENT,
    PENDING_APPROVAL,
    PENDING_EXTERNAL_SIGNATURE,
    PENDING_EXTERNAL_SIGNATURE_GC_VIEW,
    PENDING_EXTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_INTERNAL_SIGNATURE,
    PENDING_INTERNAL_SIGNATURE_SUB_VIEW,
    PENDING_SUBMISSION,
    REVOKED
} from "@/domain/Entities/Task/taskStatuses";
import Tasks from "@/api/v2/endpoints/Tasks";
import documentDownload from "@/mixin/documentDownload";

export default {
    inject: ['toast'],
    mixins: [documentDownload],
    data() {
        return {
            taskPageData: [
                'id',
                'name',
                'note',
                'type',
                'task_id',
                'status',
                'is_main',
                'scope_id',
                'internal_signer_id',
                'internal_assignee_id',
                'external_assignee_id',
                'external_signer_id',
                'complete_date',
                'start_date',
                'due_date',
                'is_overdue',
                'file_name'
            ],
            gcTaskPageInstructions: [],
            subTaskPageInstructions: [],
            loadingTaskUploadDelete: false,
            loadingTaskFileDownload: false,
        }
    },
    computed: {
        ...mapGetters(['user']),
        isTaskSubContractor() {
            return this.user.company.id === this.task.external_company?.id
                || this.user.company.id === this.task.external_company_id;
        },
        isTaskGeneralContractor() {
            return this.user.company.id === this.task.internal_company?.id
                || this.user.company.id === this.task.internal_company_id;
        },
        isTaskExternalAssignee() {
            return this.user.id === this.task.external_assignee_id;
        },
        isTaskExternalSigner() {
            return this.user.id === this.task.external_signer_id;
        },
        isTaskInternalAssignee() {
            return this.user.id === this.task.internal_assignee_id;
        },
        isTaskInternalSigner() {
            return this.user.id === this.task.internal_signer_id;
        },
        isReadyForExternalSignature() {
            return this.isPendingExternalSignature && this.isTaskExternalSigner;
        },
        isReadyForInternalSignature() {
            return this.isPendingInternalSignature && this.isTaskInternalSigner;
        },
        isContract() {
            return this.task.type === templateTypes.CONTRACT_TEMPLATE_TYPE;
        },
        isAcknowledge() {
            return this.task.type === templateTypes.ACKNOWLEDGE_TEMPLATE_TYPE;
        },
        isRequest() {
            return this.task.type === templateTypes.REQUEST_DOCUMENT_TEMPLATE_TYPE;
        },
        isPendingExternalSignature() {
            return this.task.status === PENDING_EXTERNAL_SIGNATURE;
        },
        isPendingInternalSignature() {
            return this.task.status === PENDING_INTERNAL_SIGNATURE;
        },
        isPendingSignature() {
            return this.isPendingExternalSignature || this.isPendingInternalSignature;
        },
        isPendingAcknowledgement() {
            return this.task.status === PENDING_ACKNOWLEDGEMENT;
        },
        isPendingSubmission() {
            return this.task.status === PENDING_SUBMISSION;
        },
        isPendingApproval() {
            return this.task.status === PENDING_APPROVAL;
        },
        isFullyExecuted() {
            return this.task.status === FULLY_EXECUTED;
        },
        isCompleted() {
            return this.task.status === COMPLETED;
        },
        isRevoked() {
            return this.task.status === REVOKED;
        },
        statusDescription() {
            if (this.isPendingExternalSignature)
                return this.isTaskSubContractor
                    ? PENDING_EXTERNAL_SIGNATURE_SUB_VIEW
                    : PENDING_EXTERNAL_SIGNATURE_GC_VIEW;

            if (this.isPendingInternalSignature && this.isTaskSubContractor)
                return PENDING_INTERNAL_SIGNATURE_SUB_VIEW;

            return this.task.status;
        },
        taskPageInstructions() {
            if (!this.task) return [];

            return this.isTaskGeneralContractor ? this.gcTaskPageInstructions : this.subTaskPageInstructions;
        }
    },
    methods: {
        async confirmTaskUploadDelete(documentName, callback) {
            await this.$refs.confirmDialog
                .confirm({
                    text: `Are you sure you want to delete the ${documentName}?`,
                    cancelText: 'Cancel',
                    confirmText: 'Delete',
                    reverse: true,
                })
                .then(confirm => {
                    if (confirm) this.deleteTaskUpload(callback)
                });
        },
        deleteTaskUpload(callback) {
            this.toggleLoadingTaskUploadDelete();

            Tasks
                .deleteUploadedFile(this.task.id)
                .then(() => {
                    this.toast('info', 'Task document successfully deleted!');
                    callback();
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoadingTaskUploadDelete());
        },
        downloadTaskFile() {
            this.handleTaskDownloadPromise(Tasks.downloadFile(this.task.id));
        },
        downloadTaskUploadedFile() {
            this.handleTaskDownloadPromise(Tasks.downloadUploadedFile(this.task.id));
        },
        handleTaskDownloadPromise(promise) {
            this.toggleLoadingTaskFileDownload();

            this.handleDownloadPromise(
                promise,
                `task-${this.task.id}-file`,
                this.task.file_name,
                () => this.toggleLoadingTaskFileDownload()
            );
        },
        toggleLoadingTaskUploadDelete() {
            this.loadingTaskUploadDelete = !this.loadingTaskUploadDelete;
        },
        toggleLoadingTaskFileDownload() {
            this.loadingTaskFileDownload = !this.loadingTaskFileDownload;
        }
    }
}
