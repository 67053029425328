<template>
    <CBadge class="task-badge" :size="size" :color="defineBadgeColor()">{{ statusDescription }}</CBadge>
</template>

<script>
import {mapGetters} from "vuex";
import taskHelper from "@/mixin/taskHelper";

export default {
    name: 'TaskStatusBadge',
    mixins: [taskHelper],
    props: {
        task: Object,
        size: {
            type: String,
            default: 'sm',
            validator: (value) => ['sm', 'md'].includes(value)
        }
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        defineBadgeColor() {
            if (['Fully Executed', 'Completed'].includes(this.task.status)) {
                return 'success';
            }

            if (['Revoked', 'Cancelled'].includes(this.task.status)) {
                return 'danger';
            }

            return 'warning';
        }
    }
}
</script>
