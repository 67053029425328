<template>
    <div class="review-contract">
        <div v-if="scope">
            <InstanceInfo :name-disabled="true" :info-list="infoList" :date="lastUpdateDescription(scope.last_activity)"
                          :name="scope.name"/>

            <div class="review-contract__content" :aria-disabled="loading">
                <div class="review-contract__table">
                    <div class="review-contract__table-header">
                        <CIcon name="cilCrownFilled"/>
                        <h3 class="review-contract__table-title">Primary Contract Package</h3>
                        <div class="actions">
                            <CButton v-if="isContractSent && isThereUnassignedTasks" color="primary" variant="outline"
                                     class="mr-2" @click="openRePushCommitmentTasks">
                                Update selected documents
                            </CButton>
                            <CButton color="primary" variant="outline" @click="modalLibraryPrimary = true">
                                Add new document
                            </CButton>
                        </div>
                    </div>
                    <FromLibrary v-model="modalLibraryPrimary" :except-library-type="['label']"
                                 :template-only="['Request documents', 'Request', 'Acknowledge', 'Contracts']"
                                 @addTemplate="addTemplate($event, 'primary')"/>
                    <ReviewContractTable :columns="columns" table-name="review-primary" :items="primaryTasks"
                                         @save="onSave" @task-delete="deleteTask"
                                         @changes-commited="changesCommited = true"/>
                </div>

                <div class="review-contract__table" style="margin-bottom: 70px">
                    <div class="review-contract__table-header">
                        <CIcon name="cilClockCalendar"/>
                        <h3 class="review-contract__table-title">Flowdown</h3>
                        <CButton color="primary" variant="outline" class="actions"
                                 @click="modalLibrarySecondary = true">
                            Add new document
                        </CButton>
                        <FromLibrary v-model="modalLibrarySecondary" :except-library-type="['label']"
                                     :template-only="['Request documents', 'Request', 'Acknowledge', 'Contracts']"
                                     @addTemplate="addTemplate($event, 'secondary')"/>
                    </div>
                    <ReviewContractTable :columns="columns" table-name="review-secondary"
                                         :items="secondaryTasks" @save="onSave" @task-delete="deleteTask"
                                         @changes-commited="changesCommited = true"/>
                </div>
            </div>

            <StickyFooter class="review-contract__footer">
                <CButton :disabled="loading" color="primary" variant="outline" @click="onSave('draft')">
                    Save draft
                </CButton>

                <CButton :disabled="loading" color="primary" @click="onSave(null)"> Save & next</CButton>

                <CSpinner v-show="loading" color="primary"/>
            </StickyFooter>
        </div>

        <div v-else class="review-contract__loading">
            <CSpinner color="primary"/>
        </div>
    </div>
    <ConfirmDialog ref="confirmDialog"/>
    <AppModal :visible="rePushCommitmentModalVisible" title="Re-Push Commitment"
              text="Below are the documents you previously sent to this vendor. <br> Use the checkboxes below to select the documents your would like to recreate and resend."
              @close="toggleRePushCommitmentModalVisible">
        <template #default class="mt-2">
            <div class="fw-bold">Primary Contract:</div>
            <div v-for="(task, index) in primaryRePushCommitmentTasks" :class="{'mt-2' : index === 0}" class="mb-2">
                <input type="checkbox" :id="task.id" :value="task.id" v-model="selectedRePushCommitmentTasks">
                <label :for="task.id" class="ml-2">
                    {{ task.name }}
                    <TaskStatusBadge :task="task"/>
                </label>
            </div>
            <div class="fw-bold mt-4">Flowdown:</div>
            <div v-for="(task, index) in secondaryRePushCommitmentTasks" :class="{'mt-2' : index === 0}" class="mb-2">
                <input type="checkbox" :id="task.id" :value="task.id" v-model="selectedRePushCommitmentTasks">
                <label :for="task.id" class="ml-2">
                    {{ task.name }}
                    <TaskStatusBadge :task="task"/>
                </label>
            </div>
            <p class="text-error small" v-if="showRePushCommitmentRequiredError">
                Please, select at least one task above.
            </p>
            <p class="text-information small mt-4">
                <strong>Warning:</strong>
                Completed tasks will be revoked and your vendor will be asked to resubmit.
            </p>
        </template>
        <template #footer>
            <CButton color="primary" variant="outline" @click="toggleRePushCommitmentModalVisible">Cancel</CButton>
            <CButton color="primary" @click="rePushCommitmentStepTwo">Confirm</CButton>
        </template>
    </AppModal>
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog.vue';
import FromLibrary from '@/components/Template/Add/FromLibrary.vue';
import StickyFooter from '@/components/Forms/Partials/StickyFooter.vue';
import InstanceInfo from '@/components/Forms/Partials/InstanceInfo.vue';
import ReviewContractTable from '@/components/Tables/ReviewContractTable.vue';
import Scopes from "@/api/v2/endpoints/Scopes";
import unsavedChanges from "@/mixin/unsavedChanges";
import recordActivity from "@/mixin/recordActivity";
import TaskStatusBadge from "@/components/Task/TaskStatusBadge.vue";
import AppModal from "@/components/Modals/AppModal.vue";
import Tasks from "@/api/v2/endpoints/Tasks";
import apiErrorHandler from "@/mixin/apiErrorHandler";
import pushCommitmentHelper from "@/mixin/scopes/pushCommitmentHelper";
import {REVOKED} from "@/domain/Entities/Task/taskStatuses";

export default {
    name: 'ReviewContract',
    components: {
        AppModal,
        TaskStatusBadge,
        StickyFooter,
        ConfirmDialog,
        FromLibrary,
        ReviewContractTable,
        InstanceInfo
    },
    inject: ['toast'],
    mixins: [unsavedChanges, recordActivity, apiErrorHandler, pushCommitmentHelper],
    async beforeRouteLeave() {
        return this.triggerUnsavedChangesCheck(this.changesCommited);
    },
    data() {
        return {
            scope: null,
            tasks: [],
            rePushCommitmentModalVisible: false,
            showRePushCommitmentRequiredError: false,
            selectedRePushCommitmentTasks: [],
            rePushCommitmentTasks: [],
            columns: [
                {key: 'name', label: 'Name', _style: '', sorter: false},
                {key: 'file_name', label: 'Document', _style: '', sorter: false},
                {key: 'task_action', label: 'Action', _style: '', sorter: false},
                {key: 'assign_date', label: 'Assigned Date', _style: '', sorter: false},
                {key: 'due_date', label: 'Due Date', _style: '', sorter: false},
            ],
            modalLibraryPrimary: false,
            modalLibrarySecondary: false,
            changesCommited: false,
            loading: false,
        };
    },
    computed: {
        primaryTasks() {
            return this.tasks.filter(task => task.priority_type === 1);
        },
        secondaryTasks() {
            return this.tasks.filter(task => task.priority_type === 2);
        },
        primaryRePushCommitmentTasks() {
            return this.rePushCommitmentTasks.filter(task => task.priority_type === 1);
        },
        secondaryRePushCommitmentTasks() {
            return this.rePushCommitmentTasks.filter(task => task.priority_type === 2);
        },
        isContractSent() {
            return this.scope && this.scope.status === 'contract sent';
        },
        isThereUnassignedTasks() {
            return (this.scope && this.scope.unassigned_tasks_amount > 0) || this.tasks.length > 0;
        },
        infoList() {
            return [
                {label: 'Selected package', value: this.scope.template_folder.name},
                {label: 'Company Name (Sub)', value: this.scope.external_company.name},
                {label: 'Default Signer Name (Sub)', value: this.scope.external_signer.full_name},
            ];
        },
    },
    async created() {
        await this.loadScopeData();
        this.$emit('lastAvailableStep', this.scope.step)
        this.setUpPackage();
    },
    methods: {
        async loadScopeData() {
            await Scopes
                .show(this.$route.params.scopeId, {
                    scope_data: 'id,name,status,step',
                    with_external_company: 'name',
                    with_external_signer: 'full_name',
                    with_template_folder: 'name',
                    with_unassigned_tasks_amount: 1,
                    with_last_activity: 1,
                })
                .then(async response => {
                    await this.checkPushCommitmentStep(response.data.step);
                    this.scope = response.data;
                });
        },
        setUpPackage() {
            if (this.isContractSent && !this.isThereUnassignedTasks) {
                return this.openRePushCommitmentTasks();
            }

            this.pushCommitmentStepTwoSetup();
        },
        pushCommitmentStepTwoSetup() {
            this.toggleLoading();

            Scopes
                .pushCommitmentStepTwoSetup(this.scope.id, {
                    replacing_task_ids: this.selectedRePushCommitmentTasks,
                    with_task_settings: 1,
                    with_template: 'id',
                    with_upload: 'all'
                })
                .then(response => {
                    this.tasks = response.data;
                })
                .catch(response => this.handleApiError(response))
                .finally(() => this.toggleLoading());
        },
        async openRePushCommitmentTasks() {
            this.toggleLoading();
            this.showRePushCommitmentRequiredError = false;
            this.selectedRePushCommitmentTasks = [];

            if (this.rePushCommitmentTasks.length === 0) {
                await this.loadRePushCommitmentTasks();
            }

            this.defineSelectedRePushCommitmentTasks();
            this.toggleRePushCommitmentModalVisible();
            this.toggleLoading();
        },
        async loadRePushCommitmentTasks() {
            await Tasks
                .index({
                    is_assigned: 1,
                    scope_id: this.scope.id,
                    task_data: 'id,name,status,priority_type',
                    with_external_company: 'id',
                    with_internal_company: 'id',
                    except_statuses: [REVOKED],
                    has_template: 1
                })
                .then(response => (this.rePushCommitmentTasks = response.data));
        },
        rePushCommitmentStepTwo() {
            if (this.selectedRePushCommitmentTasks.length === 0) {
                return this.showRePushCommitmentRequiredError = true;
            }

            this.pushCommitmentStepTwoSetup();
            this.toggleRePushCommitmentModalVisible();
        },
        defineSelectedRePushCommitmentTasks() {
            this.selectedRePushCommitmentTasks = this.rePushCommitmentTasks
                .filter(rePushCommitmentTask => {
                    return this.tasks.some(task => task.replacing_task_id === rePushCommitmentTask.id)
                })
                .map(rePushCommitmentTask => rePushCommitmentTask.id);
        },
        toggleRePushCommitmentModalVisible() {
            this.rePushCommitmentModalVisible = !this.rePushCommitmentModalVisible;
        },
        addTemplate(template, type) {
            Scopes
                .pushCommitmentStepTwoAddDocument(this.scope.id, {
                    template_id: template.template_id,
                    priority: type
                }, {
                    with_template: 'id',
                    with_upload: 'all'
                })
                .then(response => {
                    this.tasks.push(response.data);
                    this.toast('info', 'The document has been successfully added!');
                })
        },
        onSave(action) {
            this.toggleLoading();

            const isDraft = action === 'draft';

            if (!this.primaryTasks.length && !this.secondaryTasks.length) {
                this.toast('warning', 'Impossible to save. At least one template is required');
                return this.toggleLoading();
            }

            const data = {
                is_draft: isDraft,
                tasks: [...this.definePrimaryTasksPayload(), ...this.defineSecondaryTasksPayload()],
            };

            Scopes.pushCommitmentStepTwoSubmit(this.scope.id, data)
                .then(() => {
                    this.changesCommited = false;
                    this.toast('info', 'Changes have been saved successfully');

                    if (!isDraft) {
                        this.$router.push({name: 'ProjectScopesTaskMergeFields'});
                    }
                })
                .catch(() => this.toast('warning', 'Error updating the package'))
                .finally(() => this.toggleLoading());
        },
        definePrimaryTasksPayload() {
            return this.primaryTasks.map(task => this.defineTaskPayload(task));
        },
        defineSecondaryTasksPayload() {
            return this.secondaryTasks.map(task => this.defineTaskPayload(task));
        },
        defineTaskPayload(task) {
            return {
                id: task.id,
                description: task.description,
                is_indefinite: task.is_indefinite,
                start_date: task.start_date,
                due_date: task.due_date,
                ...task.task_recurrence,
            };
        },
        deleteTask(removedTask) {
            this.tasks = this.tasks.filter(task => task.id !== removedTask.id);
            this.toast('info', 'The template has been deleted successfully');
        },
        toggleLoading() {
            this.loading = !this.loading;
        }
    },
};
</script>

<style scoped lang="scss">
.review-contract {
    background: white;
    padding: 24px;
    border-radius: 0 0 8px 8px;
    position: relative;

    &__footer {
        & .spinner-border {
            height: 25px;
            width: 25px;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
    }

    &__content {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__table {
        &-header {
            display: flex;
            align-items: center;
            gap: 4px;
            margin-bottom: 24px;

            .actions {
                margin-left: auto;
            }
        }

        &-title {
            font-weight: 600;
            font-size: 20px;
            color: #1c262f;
            margin-bottom: 0;
        }
    }
}
</style>
